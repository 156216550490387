import React from 'react';

import config from 'resources/assets/react-app/config';

const Http = {
	get: function (url, data, fetchConfig, formData) {
		return DMFetch(url, 'GET', data, fetchConfig, formData);
	},
	post: function (url, data, fetchConfig, formData) {
		return DMFetch(url, 'POST', data, fetchConfig, formData);
	},
	put: function (url, data, fetchConfig, formData) {
		return DMFetch(url, 'PUT', data, fetchConfig, formData);
	},
	delete: function (url, data, fetchConfig, formData) {
		return DMFetch(url, 'DELETE', data, fetchConfig, formData);
	},
};

function DMFetch(url, method, data, fetchConfig, formData) {
	if (!fetchConfig) fetchConfig = {};

	if (config && config.dataDomain) {
		url = config.dataDomain + url;
	}

	const headers = {};

	headers['X-Requested-With'] = 'XMLHttpRequest';

	const user = window.reduxStore.getState().user;

	let currentTeam;

	if (user && user.currentTeam) {
		currentTeam = user.currentTeam.id;
	} else if (location.protocol === 'https:') {
		try {
			currentTeam = JSON.parse(localStorage.getItem('currentTeam'))[user.id];
		} catch (e) {}
	}

	if (currentTeam) {
		headers['X-Current-Team'] = currentTeam;
	}

	headers['X-Socket-ID'] = user.socketId;

	headers['Accept'] = 'application/json';

	if (method != 'GET' && data) {
		headers['Content-Type'] = 'application/json';
	}

	if (data && formData) {
		throw new Error('Can not have both json and formdata in a request');
	}

	if (data) {
		data = JSON.stringify(data);
	} else if (formData instanceof FormData) {
		data = formData;
	}

	const loc = new URLSearchParams(document.location.search);

	if (loc.get('noLogin')) {
		let url1 = url.split('?');

		if (!url1[1]) {
			url1[1] = '';
		}

		url1[1] = 'noLogin=1&' + url1[1];

		url = url1.join('?', url1);
	}

	return fetch(url, {
		method,
		headers,
		credentials: 'include', //'same-origin',
		body: data,
	})
		.then((response) => {
			if (response.status == 401) {
				let se = window.location.search;

				se = se.replace('noLogin=1', '');

				document.location =
					'/login?redi=' + encodeURIComponent(document.location.pathname + se);
				return {};
			}

			if (response.status == 406) {
				document.location = '/logout';
				return {};
			}

			if (Math.floor(response.status / 100) == 4) {
				return {};
			}

			if (fetchConfig.returnResponse) {
				return response;
			}

			if (response.ok) {
				if (fetchConfig.returnAllData) {
					return responseToJson(response);
				}

				return returnDataFromResponse(response);
			}

			return {};
		})
		.catch((e) => {
			throw e;
		});
}

function returnDataFromResponse(response) {
	return responseToJson(response).then((data) => {
		if (data.errors) {
			data.errors.map((m1) => {
				import(/* webpackChunkName: "error-modal" */ './modals/modal').then(
					(showModal) => {
						const header = m1.heading ?? 'Error';

						const r = React; // need this to stop Prettier removing React import needed to render the message

						if (m1.link) {
							m1.message = (
								<div>
									{m1.message}
									<br />
									<br />
									<div className='text-center'>
										<a href={m1.link} className='btn btn-primary'>
											Click here
										</a>
									</div>
								</div>
							);
						}
						showModal.default({
							id: header,
							header,
							body: m1.message,
						});
					}
				);
			});
		}

		return data.data;
	});
}

function responseToJson(response) {
	return response.text().then((text) => {
		try {
			return JSON.parse(text);
		} catch (e) {
			return { data: {} };
		}
	});
}

export default Http;
