import asyncComponent from 'Demora/AsyncComponent';

const UserProfile = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "user_profile" */ '../../js/website_traffic/home/UserProfile'
		)
);

const blog = asyncComponent(
	() => import(/* webpackChunkName: "blog" */ '../../js/Blog/Blog')
);

const admin = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-admin-admin" */ '../../../../vendor/z5internet/demora/src/resources/assets/js/src/app/Auth/Admin/admin'
		)
);

const thankYou = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-thank-you" */ '../../js/website_traffic/home/upgrade/thank_you'
		)
);

const HomePage = asyncComponent(
	() => import(/* webpackChunkName: "prot-Homepage" */ '../components/HomePage')
);

const Videos = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-videos" */ '../../js/website_traffic/home/read/Videos'
		)
);

export default [
	{
		path: '/website_traffic/home/user_profile.php',
		component: UserProfile,
	},
	{
		path: '/blog',
		component: blog,
	},
	{
		path: '/blog/:bid',
		component: blog,
	},
	{
		path: '/admin/:service/:tab',
		component: admin,
	},
	{
		path: '/website_traffic/home/upgrade/thank_you.php',
		component: thankYou,
	},
	{
		path: '/website_traffic/home/read/videos',
		component: Videos,
	},
	{
		path: '*',
		exact: false,
		component: HomePage,
	},
];
