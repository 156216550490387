import React from 'react';

import classnames from 'classnames';

import './CJCountdown.scss';

import CJLogo from './logo.png';

class CJCountdown extends React.Component {
	constructor() {
		super();

		this.state = {
			days: '',
			hours: '',
			launched: false,
			minutes: '',
			seconds: '',
		};
	}

	componentDidMount() {
		this.countDownDate(true);
	}

	countDownDate = (first) => {
		const timeleft = this.getEndDate().getTime() - new Date().getTime();

		if (timeleft < 0) {
			clearInterval(this.interval);
			this.setState({
				launched: true,
			});
			if (!first && this.props.onCountdownZero) {
				this.props.onCountdownZero();
			}
			return;
		}

		const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
		const hours = Math.floor(
			(timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
		);
		const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((timeleft % (1000 * 60)) / 100) / 10;

		this.setState({
			days,
			hours,
			minutes,
			seconds,
		});

		if (!this.interval) {
			this.interval = setInterval(this.countDownDate, 90);
		}
	};

	getEndDate() {
		const rel = '2023-06-09 18:00:00'.split(' ');
		const date = rel[0].split('-');

		const time = rel[1].split(':');

		return new Date(
			Date.UTC(date[0], date[1] - 1, date[2], time[0], time[1], time[2])
		);
	}

	getLaunchDate = () => {
		const d = this.getEndDate();

		const months = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		];

		const year = d.getFullYear();
		const date = d.getDate();
		const month = months[d.getMonth()];
		const hour = d.getHours().toString().padStart(2, '0');
		const minute = d.getMinutes().toString().padStart(2, '0');

		return `${month} ${date} ${year} ${hour}:${minute}`;
	};

	render() {
		const state = this.state;

		const sec = state.seconds.toFixed && state.seconds.toFixed(1);

		const props = this.props;

		let launched = false;

		if (this.getEndDate().getTime() <= new Date().getTime()) {
			return null;
		}

		return (
			<a
				href='https://cashjuice.com/blog/73'
				style={{ textDecoration: 'none' }}
				target='_blank'
			>
				<div
					className={classnames(
						'NewPlanCountdown d-flex justify-content-center text-white',
						props.className
					)}
					style={props.style}
				>
					<img
						src={CJLogo}
						style={{ width: '154px', height: '48px' }}
						className='d-none d-sm-block'
					/>
					<div className='countdown d-flex justify-content-center'>
						<div>
							<div>{state.days}</div>
							<div>Days</div>
						</div>
						<div>
							<div>{state.hours}</div>
							<div>Hrs</div>
						</div>
						<div>
							<div>{state.minutes}</div>
							<div>Mins</div>
						</div>
						<div>
							{!!sec && (
								<div
									className='text-sm-right d-flex'
									style={{ marginRight: '2px' }}
								>
									<div className='flex-grow-1' style={{ width: '40px' }}>
										{sec.split('.')[0]}
									</div>
									<div className='d-none d-sm-block' style={{ width: '5px' }}>
										.
									</div>
									<div className='d-none d-sm-block' style={{ width: '20px' }}>
										{sec.split('.')[1]}
									</div>
								</div>
							)}
							<div>Secs</div>
						</div>
					</div>
				</div>
			</a>
		);
	}
}

export default CJCountdown;
