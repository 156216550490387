import OAuthRoutes from '../../../../vendor/z5internet/ruf_oauth/src/resources/assets/js/src/app/Auth/Routes';

import asyncComponent from 'Demora/AsyncComponent';

const AdGuide = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-AdGuide" */ '../../js/website_traffic/home/read/AdGuide'
		)
);

const CancelSubscription = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "CancelSubscription" */ '../../js/home/CancelSubscription'
		)
);

const Dashboard2 = asyncComponent(
	() => import(/* webpackChunkName: "prot-AdGuide" */ '../../js/AuthHome2')
);

const Faqs = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-faqs" */ '../../js/website_traffic/home/read/Faqs'
		)
);

const Favorites = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-faqs" */ '../../js/website_traffic/home/websites/favorites'
		)
);

const FeedbackForm = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-feedback" */ '../../js/website_traffic/home/admin/feedback'
		)
);

const Invoice = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-upgrade" */ '../../js/website_traffic/home/upgrade/invoice'
		)
);

const LevelLadder = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "websites_levelladder" */ '../../js/website_traffic/home/websites/level_ladder/index'
		)
);

const LevelsOnLadder = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "websites_levelsOnladder" */ '../../js/website_traffic/home/websites/level_ladder/levels_on_ladder'
		)
);

const LevelReport = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "websites_levelsOnladder" */ '../../js/website_traffic/home/reports/level_report'
		)
);

const Points5Days = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "websites_levelsOnladder" */ '../../js/website_traffic/home/websites/points5days'
		)
);

const Websites = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "websites_levelsOnladder" */ '../../js/website_traffic/home/websites'
		)
);

const WebsitesViewed = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "websites_levelsOnladder" */ '../../js/website_traffic/home/websites/websites_viewed'
		)
);

const PaymentFailed = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "websites_levelsOnladder" */ '../../js/website_traffic/home/upgrade/payment_failed'
		)
);

const Referring = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-referring" */ '../../js/website_traffic/home/referring/index'
		)
);

const ReferringLeagues = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-referring-leagues" */ '../../js/website_traffic/home/referring/leagues'
		)
);

const ReferringReport = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-referring-report" */ '../../js/website_traffic/home/referring/report'
		)
);

const TrafficAdBarStats = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-referring-report" */ '../../js/Stats/__TrafficAdBarStats'
		)
);

const Settings = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-auth-settings" */ '../../../../vendor/z5internet/demora/src/resources/assets/js/src/app/Auth/Settings/Settings'
		)
);

const SurfBar = asyncComponent(
	() => import(/* webpackChunkName: "SurfBar" */ '../../js//SurfBar/index')
);

const Testimonial = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-testimonial" */ '../../js/home/Testimonial'
		)
);

const UpdateCard = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-updatecard" */ '../../js/website_traffic/home/upgrade/update_card'
		)
);

const Upgrade = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-upgrade" */ '../../js/website_traffic/home/upgrade'
		)
);

const UseCommissionToUpgrade = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-upgrade" */ '../../js/website_traffic/home/upgrade/useCommissionToUpgrade'
		)
);

const Support = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-support" */ '../../js/website_traffic/home/read/Support'
		)
);

const VerifyEmail = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-support" */ '../../js/settings/emailSettings/VerifyEmail'
		)
);

const Powerlink = asyncComponent(
	() =>
		import(
			/* webpackChunkName: "prot-referring-leagues" */ '../../js/website_traffic/home/powerlink'
		)
);

export default OAuthRoutes.concat([
	{
		path: '/_:path',
		component: SurfBar,
	},
	{
		path: '/home/CancelSubscription',
		component: CancelSubscription,
	},
	{
		path: '/home/testimonial',
		component: Testimonial,
	},
	{
		path: '/home/upgrade/update_card',
		component: UpdateCard,
	},
	{
		path: '/settings/commissionSettings',
		component: Settings,
	},
	{
		path: '/settings/deleteAccount',
		component: Settings,
	},
	{
		path: '/settings/emailSettings',
		component: Settings,
	},
	{
		path: '/settings/profileSettings',
		component: Settings,
	},
	{
		path: '/website_traffic/home',
		component: Dashboard2,
	},
	{
		path: '/website_traffic/home/admin/feedback.php',
		component: FeedbackForm,
	},
	{
		path: '/website_traffic/home/websites/level_report.php',
		component: LevelReport,
	},
	{
		path: '/website_traffic/home/websites/points5days.php',
		component: Points5Days,
	},
	{
		path: '/website_traffic/home/websites/websites_viewed.php',
		component: WebsitesViewed,
	},
	{
		path: '/website_traffic/home/websites/level_ladder',
		component: LevelLadder,
	},
	{
		path: '/website_traffic/home/websites/level_ladder/levels_on_ladder.php',
		component: LevelsOnLadder,
	},
	{
		path: '/website_traffic/home/read/ad_guide.php',
		component: AdGuide,
	},
	{
		path: '/website_traffic/home/read/faqs',
		component: Faqs,
	},
	{
		path: '/website_traffic/home/websites/favorites.php',
		component: Favorites,
	},
	{
		path: '/website_traffic/home/websites/:tab?',
		component: Websites,
	},
	{
		path: '/website_traffic/home/referring',
		component: Referring,
	},
	{
		path: '/website_traffic/home/referring/leagues.php',
		component: ReferringLeagues,
	},
	{
		path: '/website_traffic/home/referring/report.php',
		component: ReferringReport,
	},
	{
		path: '/home/stats/:tab1?',
		component: TrafficAdBarStats,
	},
	{
		path: '/website_traffic/home/upgrade',
		component: Upgrade,
	},
	{
		path: '/website_traffic/home/upgrade/payment_failed',
		component: PaymentFailed,
	},
	{
		path: '/website_traffic/home/upgrade/commission.php',
		component: UseCommissionToUpgrade,
	},
	{
		path: '/website_traffic/home/upgrade/invoice',
		component: Invoice,
	},
	{
		path: '/website_traffic/home/read/support',
		component: Support,
	},
	{
		path: '/website_traffic/home/admin/verify_email.php',
		component: VerifyEmail,
	},
	{
		path: '/website_traffic/home/powerlink',
		component: Powerlink,
	},
]);
