import React from 'react';

import image from 'Demora/image';

import ReduxConnect from 'Demora/redux-connect';

import http from 'Demora/http';

import { Link, withRouter } from 'react-router-dom';

class ShowBanner extends React.Component {
	banner_click = () => {
		const props = this.props;

		const { banners } = props.globalStuff;

		http.get('/data/click_on_banner?bid=' + banners[props.bid].id);
	};

	render() {
		const props = this.props;

		const { banners } = props.globalStuff;

		if (!banners) {
			return null;
		}

		const banner = banners[props.bid];

		if (!banner.id) {
			return null;
		}

		const loc = props.location;

		if (loc.pathname.match(/^\/_/) || loc.pathname.match(/^\/admin/)) {
			return null;
		}

		return (
			<div className='text-center mt-3 mb-3 flex-shrink-1'>
				<div className='d-flex justify-content-center'>
					<div className='text-center' style={{ marginRight: '10px' }}>
						<Link to={'/website_traffic/home/user_profile.php?uid=' + banner.u}>
							<img
								src={image(banner.image, 50)}
								style={{
									width: '45px',
									borderRadius: '8px',
									marginRight: '5px',
								}}
							/>
							<br />
							<span>{banner.name}</span>
						</Link>
					</div>
					<div>
						<a href={banner.url} target='_blank' onClick={this.banner_click}>
							<img
								src={'/i/' + banner.u + '-' + banner.id + '-b.jpg'}
								style={{ maxWidth: '468px', width: '100%', maxHeight: '60px' }}
							/>
						</a>
						<br />
						One of our <Link to='/website_traffic/home/upgrade'>
							upgraded
						</Link>{' '}
						members banners.
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(
	ReduxConnect(ShowBanner, {
		globalStuff: 'globalStuff',
	})
);
