import React, { Component } from 'react';

import { Link, withRouter } from 'react-router-dom';

import ReduxConnect from 'Demora/redux-connect';

import TopNavBarLogo from 'resources/assets/react-app/components/TopNavBarLogo';

const styles = {
	link: {
		fontWeight: 200,
	},
};

class TopNav extends Component {
	render() {
		const { website, match } = this.props;

		return (
			<div className='navbarTop topBarLoggedOut navbar navbar-dark fixed-top'>
				<div style={{ flexGrow: 2 }}>
					<TopNavBarLogo />
				</div>
				<ul className='nav'>
					{!website.signups ? (
						''
					) : (
						<li className='nav-item'>
							<Link
								className='nav-link'
								style={styles.link}
								to='/signup'
								rel='nofollow'
							>
								Signup
							</Link>
						</li>
					)}
					<li className='nav-item'>
						<Link
							className='nav-link'
							style={styles.link}
							to='/login'
							rel='nofollow'
						>
							Login
						</Link>
					</li>
				</ul>
			</div>
		);
	}
}

export default withRouter(
	ReduxConnect(TopNav, {
		website: 'website',
	})
);
