import loadjs from 'loadjs';

import LP from './livePusher';

import http from './utils/http';

let loadjsAttempts = 0;

class pusher {
	constructor() {
		this.cipns = null;
		this.currentSocketId = null;
		this.gettingAuth = {};
	}

	livePusherChannel = (channel) => {
		return this.livePusherListen('channel', channel);
	};

	livePusherPrivate = (channel) => {
		return this.livePusherListen('private', channel);
	};

	livePusherPresence = (channel) => {
		return this.livePusherListen('presence', channel);
	};

	livePusherLeave = (channel, event) => {
		if (this.livepusher) {
			this.livepusher.leave(channel);
		}
	};

	livePusherListen = (action, channel) => {
		return {
			action,
			channel,
			whisper: (event, callback) => {
				return this.eee(this, event, callback, 'whisper');
			},
			listenForWhisper: (event, callback) => {
				return this.eee(this, event, callback, 'listenForWhisper');
			},
			listen: (event, callback) => {
				return this.eee(action, channel, event, callback, 'listen');
			},
		};
	};

	eee = (action, channel, event, callback, action2) => {
		const lp3 = window.reduxStore.getState().website.livePusher;

		if (lp3 && lp3.app_id) {
			if (!this.cipns) {
				this.cipns = this.checkIfPusherNeedsStarting()
					.then(() => {
						this.gotCIPNS(action, channel, event, callback, action2);
					})
					.catch(() => {});
			} else {
				this.cipns
					.then(() => {
						this.gotCIPNS(action, channel, event, callback, action2);
					})
					.catch(() => {});
			}
		}

		return this.livePusherListen(action, channel);
	};

	gotCIPNS(action1, channel, event, callback, action2) {
		return this.livepusher[action1](channel)[action2](event, callback);
	}

	saveSocketId(socketId) {
		window.reduxStore.dispatch({
			type: 'STORE_USER',
			user: {
				socketId: socketId,
			},
		});
	}

	checkIfPusherNeedsStarting = () => {
		const promise = new Promise((resolve, reject) => {
			if (this.livepusher) {
				resolve(true);
			} else {
				this.loadjs(resolve);
			}
		});

		return promise.catch((e) => {
			throw e;
		});
	};

	loadjs = (resolve) => {
		const host = 'https://api.livepusher.com:6001';

		loadjs(host + '/livePusher/socket.io.js', {
			success: () => {
				if (!window.io) {
					loadjsAttempts++;

					if (loadjsAttempts < 4) {
						setTimeout(() => {
							this.loadjs(resolve);
						}, 3000);
					}
					return;
				}

				const state = window.reduxStore.getState();

				const user = state.user;

				const website = state.website;

				this.livepusher = new LP({
					broadcaster: 'socket.io',
					host,
					path: '/livePusher',
					auth: {},
					appId: website.livePusher.app_id,
					authPrivate: (socketId, channel) => {
						this.currentSocketId = socketId;

						if (
							!this.gettingAuth[channel] ||
							this.gettingAuth[channel].length == 0
						) {
							this.gettingAuth[channel] = [];

							http
								.post('/data/broadcasting/auth', {
									socket_id: socketId,
									channel_name: channel,
								})
								.then((data) => {
									this.gettingAuth[channel].map((v) => {
										if (this.currentSocketId == v.socketId) {
											v.resolve(data.auth);
										}
									});

									this.gettingAuth[channel] = [];
								})
								.catch((e) => {
									throw e;
								});
						}

						const a = new Promise((resolve, reject) => {
							this.gettingAuth[channel].push({
								resolve,
								reject,
								socketId,
							});
						});

						return a.catch((e) => {
							throw e;
						});
					},
				});

				resolve(true);
			},

			error: (pathsNotFound) => {},

			numRetries: 99,
		});
	};
}

let pus = new pusher();

export default {
	channel: pus.livePusherChannel,
	private: pus.livePusherPrivate,
	leave: pus.livePusherLeave,
	presence: pus.livePusherPresence,
};
