import React from 'react';

import { NavbarBrand } from 'reactstrap';

class TopNavBarLogo extends React.Component {
	render() {
		return (
			<NavbarBrand href='/'>
				<img
					style={{ position: 'absolute', top: '3px' }}
					src='/assets/home_logo.png'
				/>
			</NavbarBrand>
		);
	}
}

export default TopNavBarLogo;
