import isArray from 'lodash/isArray';

const liveData = (state = {}, action) => {
	switch (action.type) {
		case 'STORE_LIVE_DATA':
			if (!state.live_time || action.data.live_time > state.live_time) {
				state = Object.assign(state, action.data);
			}

			return Object.assign({}, state);

		default:
			return state;
	}
};

const countdown = (state = 0, action) => {
	switch (action.type) {
		case 'SET_SURFBAR_COUNTDOWN_TIMER':
			state = action.countdown;

			break;

		case 'REDUCE_SURFBAR_COUNTDOWN_TIMER':
			if (state > 0) {
				state--;
			}

			break;
	}

	return state;
};

const websites = (state = {}, action) => {
	switch (action.type) {
		case 'SAVE_WEBSITES':
			let data = action.data;

			if (data.countries && !isArray(data.countries)) {
				var countries = [];

				for (var cc in data.countries) {
					if (data.countries[cc]) {
						countries.push({
							code: cc,
							name: data.countries[cc].country,
							credits: data.countries[cc].credits,
						});
					}
				}

				data.countries = countries;
			}

			if (data.languages && !isArray(data.languages)) {
				var languages = [];

				for (var code in data.languages) {
					languages.push({
						value: code,
						label:
							data.languages[code].language + ' (' + code.toUpperCase() + ') ',
					});
				}

				data.languages = languages;
			}

			if (data.timer_lengths && !isArray(data.timer_lengths)) {
				var timer_lengths = [];

				for (var ti in data.timer_lengths) {
					timer_lengths.push({
						timer: ti,
						credits: data.timer_lengths[ti].credits,
					});
				}

				data.timer_lengths = timer_lengths;
			}

			data = Object.assign(state, data);

			for (var i in data.websites) {
				var re = /^https/;

				data.websites[i].websiteURLSecure =
					!!data.websites[i].website_url.match(re);

				data.websites[i].isWebsiteSecure =
					data.websites[i].websiteURLSecure &&
					data.websites[i].mixed_http_https <= 10;

				data.websites[i].showSSLStuff =
					!data.websites[i].suspend && data.websites[i].mixed_http_https > 0;

				if (!data.websites[i].timer_length) {
					data.websites[i].timer_length = 10;
				}

				if (!data.websites[i].days_to_show) {
					data.websites[i].days_to_show = [];
				}

				if (!data.websites[i].countries) {
					data.websites[i].countries = [];
				}
			}

			state = Object.assign({}, data);

			break;
	}

	return state;
};

const globalStuff = (state = {}, action) => {
	switch (action.type) {
		case 'SAVE_BANNER':
			state.banners = action.banners;

			break;
		case 'SHOW_SURF_BANNER_PROFILE':
			state.surf_bar_profile = action.profileId;

			break;
	}

	return JSON.parse(JSON.stringify(state));
};

export default {
	countdown,
	globalStuff,
	liveData,
	websites,
};
