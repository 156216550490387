import React, { Component } from 'react';

export default function asyncComponent(getComponent) {
	class AsyncComponent extends Component {
		static Component = null;

		state = {
			attempts: 0,
			Component: AsyncComponent.Component,
		};

		componentDidMount() {
			this.getComponent();
		}

		componentWillUnmount() {
			this.unmounted = true;
		}

		getComponent = () => {
			const state = this.state;

			if (!state.Component) {
				getComponent()
					.then((module) => module.default)
					.then((Component) => {
						AsyncComponent.Component = Component;

						this.setState({ Component });
					})
					.catch((error) => {
						const attempts = state.attempts;

						if (this.unmounted) {
							return;
						}

						this.setState({
							attempts: attempts + 1,
						});

						if (attempts === 10) {
							throw error;
						}

						if (attempts < 10) {
							setTimeout(this.getComponent, state.attempts * 1000);
						}
					});
			}
		};

		render() {
			const { Component } = this.state;

			if (Component) {
				return <Component {...this.props} />;
			}

			return null;
		}
	}

	return AsyncComponent;
}
