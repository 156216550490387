import React from 'react';

import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import AuthRoutes from './Auth/Routes';

import OpenRoutes from './NoAuth/Routes';

import ReduxConnect from 'Demora/redux-connect';

class Router extends React.Component {
	constructor() {
		super();

		this.state = {
			path: null,
		};
	}

	componentDidMount() {
		this.runCheck();
	}

	componentDidUpdate(prevProps) {
		const props = this.props;

		if (props.location !== prevProps.location || !this.state.path) {
			this.runCheck();
		}
	}

	runCheck() {
		const props = this.props;

		const user = props.user;

		const url = props.location.pathname;

		if (user.id && !user.finishedGetStarted && !url.match(/\/getStarted/)) {
			window.location.href = '/getStarted';

			return;
		}

		this.setState({
			path: url,
		});

		window.scrollTo(0, 0);
	}

	render() {
		const { user } = this.props;

		if (!this.state.path) {
			return null;
		}

		const routes = AuthRoutes.concat(OpenRoutes);

		const wh = this.props.heightOfInnerInnerWindow || '';

		return (
			<div style={{ height: wh, maxHeight: wh }} id='routerRoot'>
				<Switch>
					{routes.map((r, i) => {
						return (
							<Route
								key={i}
								exact={r.exact === undefined ? true : r.exact}
								path={r.path}
								render={(props) => {
									if (r.auth && !user.id) {
										let location = props.location;

										let redi = location.pathname;

										if (location.search) {
											let se = location.search;

											se = se.replace('noLogin=1', '');

											redi += se;
										}

										return (
											<Redirect
												to={'/login?redi=' + encodeURIComponent(redi)}
											/>
										);
									}

									let V1 = r.component;

									return <V1 {...props} />;
								}}
							/>
						);
					})}
				</Switch>
			</div>
		);
	}
}

export default withRouter(
	ReduxConnect(Router, {
		heightOfInnerInnerWindow: 'heightOfInnerInnerWindow',
		user: 'user',
	})
);
