import React from 'react';

import * as Sentry from '@sentry/react';

import incompatible_browser from '../js/utils/incompatible_browser';

import http from 'Demora/http';

const config = {
	addons: ['ruf_oauth'],

	dataDomain:
		typeof document === 'undefined'
			? ''
			: document.location.port == 8080
				? 'http://trafficadbar.test:8000'
				: '',
};

if (!('_self' in React.createElement('div'))) {
	Sentry.init({
		dsn: 'https://44cc7ad8ecc244cbbc9b64e79dd90e1b@sentry.io/1844126',
		ignoreErrors: [
			'Abgebrochen',
			'annulleret',
			'anulat',
			'avbruten',
			'avbrutt',

			'Could not connect to the server.',

			'Failed to fetch',

			'geannuleerd',
			'Interruption de la connexion',

			'Load failed',
			'NetworkError when attempting to fetch resource',
			'Netzwerkverbindung',
			'The operation was aborted',

			'Unexpected end of script',
			'Unexpected token',

			'window.ethereum.selectedAddress',
		],
		ignoreErrors_old: [
			'ResizeObserver',

			// Random plugins/extensions
			'top.GLOBALS',
			// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
			'originalCreateNotification',
			'canvas.contentDocument',
			'MyApp_RemoveAllHighlights',
			'http://tt.epicplay.com',
			"Can't find variable: ZiteReader",
			'jigsaw is not defined',
			'ComboSearch is not defined',
			'http://loading.retry.widdit.com/',
			'atomicFindClose',
			// Facebook borked
			'fb_xd_fragment',
			// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
			// See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
			'bmi_SafeAddOnload',
			'EBCallBackMessageReceived',
			// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
			'conduitPage',
			// Generic error code from errors outside the security sandbox
			// You can delete this if using raven.js > 1.0, which ignores these automatically.
			'Script error.',
			// Avast extension error
			'_avast_submit',

			'Blocked a frame with origin',
			'Unexpected end of input',
			'The Internet connection appears to be offline',
			'ResizeObserver',
			// 'ResizeObserver loop completed with undelivered notifications',
			// 'ResizeObserver loop limit exceeded',
			// 'Origin null is not allowed by Access-Control-Allow-Origin',
			'The network connection was lost.',
			// 'Permission denied to access property "onbeforeunload" on',
			// 'Network request failed',
			// 'Cannot redefine property: hidden',
			// 'Unexpected EOF',

			'cannot parse response',

			'Type error',
			'Unexpected EOF',
			'Unexpected end of script',
			'Unexpected identifier',
			'Unexpected token',
		],
		beforeSend(event, hint) {
			const error = hint.originalException;

			if (!window.Object || typeof window.Object.entries !== 'function') {
				// Browser is pre ES2017
				return null;
			}

			if (incompatible_browser()) {
				return null;
			}

			if (error && error.message) {
				const re1 = new RegExp('Loading CSS', 'i');
				const re2 = new RegExp('Loading chunk', 'i');

				if (error.message.match(re1) || error.message.match(re2)) {
					http
						.get('/data/start')
						.then((data) => {
							if (data.rufP) {
								Sentry.captureException(error);
							}
						})
						.catch((e) => {
							Sentry.captureException(e);
						});
					return null;
				}
			}

			return event;
		},
		allowUrls: [
			/:\/\/trafadb1.com/,
			/:\/\/trafadb2.com/,
			/:\/\/www.trafadb1.com/,
			/:\/\/www.trafadb2.com/,
			/:\/\/2\-tab.com/,
			/:\/\/trafficadbar.com/,
			/:\/\/www.trafficadbar.com/,
			/:\/\/t\-adbar.com/,
			/:\/\/www.t\-adbar.com/,
			/:\/\/t\-adbar1.com/,
			/:\/\/www.t\-adbar1.com/,
			/:\/\/t\-adbar2.com/,
			/:\/\/www.t\-adbar2.com/,
			/:\/\/t\-adbar3.com/,
			/:\/\/www.t\-adbar3.com/,
			/:\/\/t\-adbar4.com/,
			/:\/\/www.t\-adbar4.com/,
			/:\/\/t\-adbar5.com/,
			/:\/\/www.t\-adbar5.com/,
			/:\/\/t\-adbar6.com/,
			/:\/\/www.t\-adbar6.com/,
			/:\/\/t\-adbar7.com/,
			/:\/\/www.t\-adbar7.com/,
			/:\/\/t\-adbar8.com/,
			/:\/\/www.t\-adbar8.com/,
			/:\/\/t\-adbar9.com/,
			/:\/\/www.t\-adbar9.com/,
			/:\/\/t\-adbar10.com/,
			/:\/\/www.t\-adbar10.com/,
		],
		//		integrations: [new BrowserTracing()],
		denyUrls: [
			/stage\.trafficadbar\.com/,
			/trafficadbar\.test/,
			// Google Adsense
			/pagead\/js/i,
			// Facebook flakiness
			/graph\.facebook\.com/i,
			// Facebook blocked
			/connect\.facebook\.net\/en_US\/all\.js/i,
			// Woopra flakiness
			/eatdifferent\.com\.woopra-ns\.com/i,
			/static\.woopra\.com\/js\/woopra\.js/i,
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
			// Other plugins
			/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
			/webappstoolbarba\.texthelp\.com\//i,
			/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
		],

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		integrations: [new Sentry.Replay()],
	});
}

if (Element.prototype.getAttributeNames === undefined) {
	Element.prototype.getAttributeNames = function () {
		const attributes = this.attributes;
		const length = attributes.length;
		const result = new Array(length);
		for (let i = 0; i < length; i++) {
			result[i] = attributes[i].name;
		}
		return result;
	};
}

export default config;
