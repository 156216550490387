import React from 'react';

import ShowBanner from '../../js/utils/ShowBanner';

import CJCountdown from '../../js/CJCountdown/CJCountdown';

import ReduxConnect from 'Demora/redux-connect';

import { withRouter } from 'react-router-dom';

const limit = 2;

class HeaderAboveContent extends React.Component {
	constructor() {
		super();

		this.state = {
			firstPage: 0,
		};
	}
	componentDidUpdate(prevProps, prevState) {
		const props = this.props;
		if (
			this.state.firstPage <= limit &&
			props.user.id &&
			props.location.pathname != prevProps.location.pathname &&
			!props.location.pathname.match(/^\/getStarted/)
		) {
			this.setState({
				firstPage: this.state.firstPage + 1,
			});
		}
	}

	render() {
		if (this.state.firstPage > limit) {
			return null;
		}
		return (
			<div>
				{this.props.user.id && !location.pathname.match(/^\/getStarted/) && (
					<CJCountdown />
				)}
				<ShowBanner bid={0} />
			</div>
		);
	}
}

export default withRouter(
	ReduxConnect(HeaderAboveContent, {
		user: 'user',
	})
);
