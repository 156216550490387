import React from 'react';

import { Link } from 'react-router-dom';

import ReduxConnect from 'Demora/redux-connect';

class FooterNavBar extends React.Component {
	constructor(props) {
		super(props);

		if (props.user.id) {
			this.loadBrands();
		}
	}

	loadBrands() {
		import(
			/* webpackChunkName: "fontawesome" */ '@fortawesome/fontawesome-free/css/all.css'
		);
	}

	render() {
		let cols = 'col-4 col-md-4';

		return (
			<div style={{ flexGrow: 1 }}>
				<div className='d-flex'>
					<div className='row text-center mx-auto'>
						<div className={cols}>
							<Link to='/contact' className='text-white'>
								Contact
							</Link>
						</div>
						<div className={cols}>
							<Link to='/terms' className='text-white'>
								Terms
							</Link>
						</div>
						<div className={cols}>
							<Link to='/privacy' className='text-white'>
								Privacy
							</Link>
						</div>
						{!!this.props.user.id && (
							<div
								className='text-white col-12'
								style={{ whiteSpace: 'nowrap' }}
							>
								<a
									href='https://www.facebook.com/trafficadbar/'
									className='text-white d-inline-block mr-4'
									target='_blank'
								>
									<i className='fab fa-facebook-f'></i>
								</a>
								<a
									href='https://twitter.com/_trafficadbar_'
									className='text-white d-inline-block mr-4'
									target='_blank'
								>
									<i className='fab fa-twitter'></i>
								</a>
								<a
									href='https://www.youtube.com/channel/UCKG5F6OrsyY7ZTczqHgFCXw'
									className='text-white d-inline-block'
									target='_blank'
								>
									<i className='fab fa-youtube'></i>
								</a>
							</div>
						)}
					</div>
				</div>
				<div className='text-center mx-auto'>
					<small style={{ color: '#ffffff' }}>
						Copyright &copy; Z5 Internet Ltd 2009-{new Date().getFullYear()}{' '}
						incorporated under the laws of the United Kingdom.
					</small>
				</div>
			</div>
		);
	}
}

export default ReduxConnect(FooterNavBar, {
	user: 'user',
});
