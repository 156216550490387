import React from 'react';

import modal from './modal';

function alert(key, title, message, okButton = true) {
	modal({
		id: key,
		header: title ? title : null,
		body: message ? (
			<div>
				{message}
				{okButton && (
					<div className='text-center mt-2'>
						<span
							className='btn btn-primary'
							onClick={() => {
								modal.close(key);
							}}
						>
							Ok
						</span>
					</div>
				)}
			</div>
		) : null,
		dontAllowClose: true,
		noFooter: true,
	});
}

export default alert;
